interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Emily S.',
    comment:
      'Lasting Change is a game-changer! The actionable steps helped me build better habits and stay motivated. Perfect for establishing new habits.',
  },
  {
    author: 'Laura K.',
    comment: `It provides practical advice and the personalized touch makes it even more impactful. I've already seen positive changes in my life.`,
  },
  {
    author: 'Sarah L.',
    comment: `Lasting Change is one of the best self-help books I’ve ever read. The exercises are super effective, and the personalized advice really makes a difference. Love it!`,
  },
  {
    author: 'Amanda T.',
    comment:
      'This book helped me to not only to stick to my ruitine with daily exercise, reading and healthy meals, but helped me quit smoking after 10 years of it!!!!',
  },
  {
    author: 'Cassandra L.',
    comment:
      'Lasting Change is fantastic. The focus on small, consistent changes has made a huge difference for me. I doubled my income, highly effective!',
  },
  {
    author: 'Karen D.',
    comment:
      'Really helped with my weight and self-esteem problems that I had. Never thought that I could get rid of that kind of baggage.',
  },
];
